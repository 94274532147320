import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import Button from '../components/atoms/inputs/Button';

const defaultOptionForCommunityFilter = {
  db_ref: 'all',
  name: 'All',
};

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'sense of belonging',
    'ai sidekick',
    'community ai',
    'hidden gems',
    'community app',
    'flockx',
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'sense of belonging',
  'community ai',
  'ai sidekick',
  'community app',
  'hidden gems',
  'nomadic lifestyle',
  'friend finder',
  'flockx',
  'real-time',
];

export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'main_landing_cta_click', {
      event_category: 'CTA',
      event_label: 'Main Landing CTA',
    });

    window.location.href = 'https://community.flockx.io/api/auth/login';
  };

  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 bg-transparent"
    >
      <SEO
        title="Flockx - Find Your Flock | Community AI Platform"
        keywords={SEO_KEYWORDS}
        description="Flockx is an AI-powered community platform that helps you discover local insights, connect with like-minded people, and contribute to collective knowledge. Join us in shaping the future of community engagement."
        schemaMarkup={schemaMarkup}
      />

    <div className="relative h-screen">
      {/* Background with class for media query handling */}
      <div className="hero-background" />

      {/* Content container with existing Tailwind classes */}
      <div className="absolute inset-0 z-10 flex pt-24">
        <div className="w-full flex">
          <div className="w-full px-2 lg:px-6 md:pl-24 lg:pl-32 
            flex items-end md:items-center 
            pb-[15vh] lg:pb-100"
          >
            <div className="w-full mx-auto md:mx-0 
              max-w-xl md:max-w-2xl 2xl:max-w-4xl
              text-center md:text-left"
            >
              <h1 className="text-2xl md:text-4xl lg:text-5xl 2xl:text-6xl 
                font-bold tracking-regular text-white 
                mb-4 md:mb-6"
              >
                The Future of Community Knowledge
              </h1>
              
              <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl 
                font-regular text-white/90 
                mb-6 md:mb-8 
                leading-7 md:leading-8 lg:leading-9"
              >
                Earn reputation and merit by helping Community AIs grow. Contribute local insights, ensure accuracy, and share recommendations to improve suggestions for others in your local area.
              </p>

              <div>
                <Button
                  label="Get Started Now"
                  onClickHandler={handleCTAButtonClick}
                  id="Get Started Now"
                  className="!bg-primary hover:!bg-primary/90 !from-primary !to-primary !px-16"
                  isSecondaryButton={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;